.sidebar-minimized .sidebar .nav-link {
    min-width: 300px !important;
}

.sidebar-minimized .sidebar .nav-link:hover {
    min-width: 300px !important;
}

.sidebar .nav-dropdown-items {
    min-width: 250px !important;
}

.sidebar-minimized .sidebar .nav-dropdown-items .nav-item {
    min-width: 250px !important;
}


.box-dashboard-pm {
    margin-top: 10px;
    width: 100%;
    padding: 5px;
    border-radius: 2px;
    box-shadow: 1px 1px 3px 0px rgba(97, 96, 96, 0.5);
    -webkit-box-shadow: 1px 1px 3px 0px rgba(97, 96, 96, 0.5);
    -moz-box-shadow: 1px 1px 3px 0px rgba(97, 96, 96, 0.5);

    .box-icon {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .box-content {
        text-align: center;

        .text-box {
            color: #fff;
            font-weight: 500
        }

        .text-box-secondary {
            color: #e6e6e6;
            font-weight: 500
        }
    }
}

.health-status {
    height: 15px;
    width: 15px;
    border-radius: 50%;
}

.health-status-danger {
    @extend .health-status;
    background-color: #b30000;
}

.health-status-good {
    @extend .health-status;
    background-color: #047857;
}

.health-status-warning {
    @extend .health-status;
    background-color: #e68a00;
}

.health-status-default {
    @extend .health-status;
    background-color: #a8a29e;
}

.box-header-dashboard-project {
    padding: 10px;
    border-radius: 3px;
    box-shadow: 0px 0px 3px 0px rgba(97, 96, 96, 0.4);
    -webkit-box-shadow: 0px 0px 3px 0px rgba(97, 96, 96, 0.4);
    -moz-box-shadow: 0px 0px 3px 0px rgba(97, 96, 96, 0.4);
}

#map {
    width: 100%;
    height: 400px;
}

.chat {
    overflow: auto;
    height: 50vh;
}

.chat-content {
    margin: 8px 0;
    font-size: 0.8em;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;

    box-shadow: 0px 0px 3px 0px rgba(97, 96, 96, 0.4) inset;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(97, 96, 96, 0.4) inset;
    -moz-box-shadow: 0px 0px 3px 0px rgba(97, 96, 96, 0.4) inset;
}

.chat-header {
    font-weight: bold;
    color: rgb(53, 52, 52);
}

.chat-body-content {
    padding-top: 4px;
    font-weight: 500;
    color: rgb(78, 78, 78);
    display: flex;
    justify-content: space-between;
}

.chat-footer {
    padding-top: 5px;
    font-weight: bold;
    color: rgb(151, 151, 151);
    font-size: 0.8em;
    display: flex;
    justify-content: flex-end;
}

.chat-body {
    padding: 6px 10px
}

.rc-color-picker-panel {
  border: 1px solid #ccc;
}
.rc-color-picker-panel-inner {
  border: none;
  box-shadow: none;
}
.rc-color-picker-panel-board-hsv {
  border-radius: 12px;
  outline: none;
}
.rc-color-picker-panel-board-value {
  border: none;
  border-radius: 12px;
}
.rc-color-picker-panel-board-saturation {
  border: none;
  border-radius: 12px;
}
.rc-color-picker-panel-ribbon {
  border-radius: 12px;
}
.rc-color-picker-panel-wrap-preview {
  border-radius: 12px;
}
.rc-color-picker-panel-preview span {
  border-radius: 12px;
}
.rc-color-picker-panel-preview input {
  border-radius: 12px;
}
 
.leaflet-container {
  width: 100%;
  height: 50vh;
}
