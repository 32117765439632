.nowrap {
  white-space: nowrap;
}

.withBtn .modal-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.shift-schedule .ant-table-thead>tr>th {
  padding: 2px 0px !important;
  /* margin: 0 !important; */
}

.shift-schedule .ant-table-tbody>tr>td {
  padding: 2px 0px !important;
  /* margin: 0 !important; */
}

.sidebar-minimized .sidebar .nav > .nav-dropdown > .nav-dropdown-items {
	max-height: 300px !important;
	overflow: auto;
}
